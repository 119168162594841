<template>
  <div>
    <div class="header-block mb-4">
      <h4 class="font-weight-bold mb-0">
        <i class="ion ion-ios-people" />
        Investors
      </h4>
      <div class="d-flex">
        <cp-button
          size
          @click="showNewInvestorModal"
        >
          <i class="ion ion-ios-person-add" />
          Add investor
        </cp-button>
      </div>
    </div>
    <cp-create-investor-modal
      ref="cpAddInvestorModal"
    />
  </div>
</template>

<script>
import CpCreateInvestorModal from '~/pages/securitize-id/components/modal-create-investor.vue';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
  name: 'CpTableHead',
  components: {
    CpCreateInvestorModal,
    CpButton,
  },
  props: {
  },
  methods: {
    showNewInvestorModal() {
      this.$refs.cpAddInvestorModal.show();
    },
  },
};
</script>

<style scoped lang="scss">
  .header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
    }
  }
</style>
