<template>
  <div class="sec-id-investors-table-container">
    <cp-table
      ref="cpTable"
      :default-sort-by="parseQuery('order-field') || 'createdAt'"
      :default-sort-direction="parseQuery('order-direction') || 'desc'"
      checkbox
      get-data-action="securitizeId/getSecuritizeIdInvestorsList"
      :fields="tableFields"
      :data-parser="parseData"
      table-title="Securitize ID"
      :url-params="urlParams"
      rows-clickable
      @tableDataUpdated="onTableDataUpdated"
      @rowClicked="onRowClicked"
      @onSelect="selectInvestor"
      @onSelectAll="selectAllInvestors"
    >
      <template slot="tableHeader">
        <span class="headers-wrapper">
          <div class="d-flex justify-content-end p-4">
            <cp-button
              variant="outline-primary"
              icon="ios-download"
              :is-loading="downloading"
              size="sm"
              @click="downLoadCsvList"
            > Export List
            </cp-button>
            <b-button
              size="sm"
              variant="outline-primary ml-3 ion ion-ios-pricetag"
              @click="editInvestorsLabels"
            >
              <span class="font-family-roboto ml-1">{{ $t('investorsCommon.button.table.edit') }}</span>
            </b-button>
          </div>
        </span>
      </template>
      <template
        slot="countryCode"
        slot-scope="{ rowData }"
      >
        <cp-country-name :country-code="rowData.item.countryCode" />
      </template>
      <template
        slot="kycStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          :id="`${rowData.index}-status`"
          class="align-text-bottom ml-1"
          :class="getStatusClass(rowData.item)"
        >
          {{ calculateStatus(rowData.item) }}
        </b-badge>
        <b-badge
          v-if="rowData.item.kycSubStatus === 'enhance-requested'"
          :id="`${rowData.index}-is-enhance-required`"
          class="align-text-bottom badge-outline-secondary ml-1"
        >
          {{ rowData.item.kycSubStatus }}
        </b-badge>
        <b-tooltip
          v-if="(rowData.item.kycStatus === 'updates-required' || rowData.item.kycStatus === 'manual-review')
            && rowData.item.verificationErrors.length"
          :id="`${rowData.index}-status-tooltip`"
          :ref="`${rowData.index}-status-tooltip`"
          triggers="hover"
          :target="`${rowData.index}-status`"
          placement="bottom"
          custom-class="custom-tooltip"
        >
          <p>Why are updates required?</p>
          <div>
            <span
              v-for="error in rowData.item.verificationErrors"
              :key="error"
            >
              {{ getVerificationText(error) }}
            </span>
          </div>
        </b-tooltip>
      </template>
      <template
        slot="issuers"
        slot-scope="{ rowData }"
      >
        <div
          v-for="issuerId in rowData.item.issuers"
          :key="issuerId"
          class="badge badge-outline-secondary mr-2 mt-2"
        >
          {{ getIssuerName(issuerId) }}
        </div>
      </template>
      <template
        slot="labels"
        slot-scope="{ rowData }"
      >
        <div
          v-for="label in rowData.item.labels"
          :key="label.id"
          class="badge badge-outline-secondary mr-2 mt-2"
        >
          {{ label.label }}
        </div>
        <p
          v-if="!rowData.item.labels.length"
        >
          -
        </p>
      </template>
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.createdAt | dateFilterWOTz }}
        </span>
      </template>
      <template
        slot="lastLoggedIn"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.lastLoggedIn | dateFilterWOTz }}
        </span>
      </template>
      <template
        slot="kycStatusChangeDate"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.kycStatusChangeDate | dateFilterWOTz }}
        </span>
      </template>
      <template
        slot="assignedAttorneyOperatorId"
        slot-scope="{ rowData }"
      >
        <b-select
          :disabled="!canChangeAssignee"
          injected-classes="m-0"
          :value="rowData.item.assignedAttorneyOperatorId"
          :options="attorneys"
          @click.native.stop
          @input="attorneyAssigneeChange(rowData.item.id, $event, rowData.item.assignedAttorneyOperatorId)"
        />
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            variant="default btn-xs icon-btn md-btn-flat"
            @click="$emit('onViewElem', rowData.item, $event)"
          >
            <i class="ion ion-ios-eye" />
          </cp-button>
          <cp-button
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="verifyDeleteInvestor(rowData.item)"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </div>
      </template>
    </cp-table>
    <cp-confirm-modal
      ref="cpConfirmDeleteModal"
      ok-text="Delete"
      :title="confirmDeleteTitle"
      @onOk="runDeleteInvestorRequest"
    >
      {{ $t('investorDetails.removeModal.message', [$t('investor')]) }}
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="cpCantDeleteModal"
      :title="`Delete ${investorSelectedForDelete.fullName || investorSelectedForDelete.investorName}`"
      :ok-only="true"
    >
      {{
        `This investor is associated with ${investorSelectedForDelete.issuers.map(issuerId => getIssuerName(issuerId)).join(', ')}.
        If you wish to delete investors you must first unlink them from issuers.`
      }}
    </cp-confirm-modal>
    <labels-modal
      ref="labelsModal"
      :modal-id="modalId"
      :error-field="errorField"
      :selected-invest="isSelectable"
      :hide-create-label="hideCreateLabel"
      :result-filter-labels="resultFilterLabels"
      :predefined-labels="resultFilterPredefinedLabels"
      @onApplyEditLabels="handleEditLabels"
      @onApplyFilterLabels="handleFilterLabels"
      @onHide="errorField = false"
      @onCreateLabel="handleCreateLabel"
      @searchLabel="handleSearchLabel"
      @onDeleteLabel="handleDeleteLabel"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState, mapGetters } from 'vuex';
import { camelCase } from 'change-case';
import CpCountryName from '~/components/common/country-name';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpTimeConverter from '~/mixins/time-converter';
import LabelsModal from '../labelsModal';
import { kycStatuses, tableFields, filterLists } from './options';

export default {
  name: 'CpSecuritizeIdTable',
  components: {
    CpCountryName,
    CpTable,
    CpButton,
    CpConfirmModal,
    LabelsModal,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      downloading: false,
      kycStatuses,
      tableFields,
      totalTableData: [],
      isEmplyList: false,
      investorSelectedForDelete: { issuers: [] },
      confirmDeleteTitle: '',
      errorField: false,
      hideCreateLabel: false,
      modalId: 'edit-label-modal',
      verificationErrors: filterLists.verificationErrors,
      listSelectedInvest: [],
      labels: [],
      predefinedLabels: [],
    };
  },
  computed: {
    ...mapGetters('global', ['issuers']),
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapState('issuerList', ['allSystemIssuersList']),
    ...mapState('operators', ['operatorsList']),
    isSelectable() {
      return !!this.listSelectedInvest.length;
    },
    resultFilterLabels() {
      return this.filterLabels(this.labels);
    },
    resultFilterPredefinedLabels() {
      return this.filterLabels(this.predefinedLabels);
    },
    urlParams() {
      const checkItemArray = item => (Array.isArray(item) ? item : [item]);
      const transformItems = {
        countries: checkItemArray,
        states: checkItemArray,
        'issuer-list': checkItemArray,
        'investor-type': checkItemArray,
        verificationStatus: checkItemArray,
        verificationErrors: checkItemArray,
        'from-last-logged-in': item => decodeURIComponent(item),
        'to-last-logged-in': item => decodeURIComponent(item),
        assignedAttorneyOperatorId: checkItemArray,
        page: item => Number(item),
        'order-field': item => decodeURIComponent(item),
        'order-direction': item => decodeURIComponent(item),
      };

      return Object.keys(this.$route.query)
        .reduce((accum, key) => (transformItems[key] && this.$route.query[key]
          ? { ...accum, [key]: transformItems[key](this.$route.query[key]) } : accum), {});
    },
    canChangeAssignee() {
      return this.operatorData.authorizations.includes('securitize-id');
    },
    attorneys() {
      const currentOperator = this.operatorsList
        .find(operator => operator.name.toUpperCase() === this.operatorData.name.toUpperCase());
      if (!currentOperator) return [];

      const operators = this.operatorsList
        .filter(operator => (operator.name !== currentOperator.name && operator.authorizations.includes('securitize-id')))
        .reduce((acc, { id, name }) => ([...acc, { value: id, text: name }]), [])
        .sort((a, b) => {
          const nameA = a.text.toUpperCase();
          const nameB = b.text.toUpperCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;

          return 0;
        });

      operators.splice(0, 0, { value: currentOperator.id, text: currentOperator.name });
      operators.splice(1, 0, { value: 'none', text: 'None' });
      operators.splice(2, 0, { value: 'compliance-team', text: 'Compliance Team' });
      operators.splice(3, 0, { value: 'operations-team', text: 'Operations Team' });

      return operators;
    },
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  async created() {
    const { data: { data: { labels, predefinedLabels } } } = await this.getLabels();
    this.labels = labels;
    this.predefinedLabels = predefinedLabels;
  },
  methods: {
    ...mapActions('securitizeId', ['getSecuritizeIdInvestorsListCsv', 'deleteSecuritizeIdInvestor', 'getLabels',
      'createLabel', 'deleteLabel', 'updateInvestorsLabels']),
    calculateStatus(item) {
      const blocked = item.kycStatus === 'rejected'
          && item.kycSubStatus === 'blocked'
          && 'rejected[blocked]';
      const documentsExpired = item.kycStatus === 'verified'
          && item.kycSubStatus === 'documents-expired'
          && 'verified[documents-expired]';
      const expired = item.kycStatus === 'verified'
          && item.kycSubStatus === 'expired'
          && 'verified[expired]';
      const enhanceVerified = item.kycStatus === 'verified'
          && item.kycStatusLevel === 'enhanced'
          && 'enhance-verified';

      return blocked || documentsExpired || expired || enhanceVerified || item.kycStatus;
    },
    onTableDataUpdated({ items, totalItems }) {
      this.totalTableData = items;
      this.isEmplyList = totalItems === 0;
    },
    parseData(data) {
      return data.map(item => ({
        ...item,
        id: item.securitizeIdProfileId,
        assignedAttorneyOperatorId: item.assignedAttorneyOperatorId || 'none',
      }));
    },
    async downLoadCsvList() {
      const params = this.$refs.cpTable.joinTableParams;
      this.downloading = true;
      this.getSecuritizeIdInvestorsListCsv({
        params,
        fileName: `Securitize_Id_Investors_${moment()
          .format('YYYY-MM-DD, h-mm')}`,
      })
        .catch((error) => {
          this.downloading = false;
          this.$log.error('Investors export error:', error);
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    verifyDeleteInvestor(investorRowItem) {
      this.investorSelectedForDelete = investorRowItem;
      const isInvestorAssociatedToIssuers = !!investorRowItem.issuers.length;

      if (isInvestorAssociatedToIssuers) {
        this.$refs.cpCantDeleteModal.show();
      } else {
        this.confirmDeleteTitle = this.$t('investorDetails.removeModal.title', [investorRowItem.fullName]);
        this.$refs.cpConfirmDeleteModal.show();
      }
    },
    runDeleteInvestorRequest() {
      this.deleteSecuritizeIdInvestor({ investorId: this.investorSelectedForDelete.id })
        .then(() => {
          this.$refs.cpTable.updateTableData();
        });
    },
    getIssuerName(issuerId) {
      const foundIssuer = (this.allSystemIssuersList || []).find(issuer => issuer.issuerId === issuerId);
      return foundIssuer && foundIssuer.name;
    },
    onRowClicked({ data: item, e }) {
      this.$emit('onViewElem', item, e);
    },
    getStatusClass(item) {
      return this.kycStatuses[item.kycStatus];
    },
    // investors labels
    async handleSearchLabel(q) {
      await this.getAndParseLabels({ q });
    },
    async handleDeleteLabel(labelId) {
      await this.deleteLabel(labelId);
      this.$refs.cpTable.updateTableData();
      await this.getAndParseLabels();
    },
    async handleCreateLabel(label) {
      if (!label) {
        this.errorField = true;
      }
      await this.createLabel(label);
      await this.getAndParseLabels();
    },
    getSelectedLabels(data) {
      const labelsNames = Object.keys(data);
      const labelsToAdd = labelsNames.filter(elem => data[elem].checked);
      const labelsToRemove = labelsNames.filter(elem => !data[elem].checked && !data[elem].indeterminate);
      return {
        labelsToAdd,
        labelsToRemove,
      };
    },
    handleFilterLabels(data) {
      const labelsToFilter = Object.keys(data).filter(label => data[label].checked);

      if (labelsToFilter.length) {
        this.$set(this.tableFilters, 'labels', labelsToFilter.join());
      } else {
        this.$delete(this.tableFilters, 'labels');
      }
      this.$refs.cpTable.updateTableData();
      this.$nextTick(() => {
        this.$refs.labelsModal.$refs.LabelsModal.hide();
      });
    },
    handleEditLabels(data) {
      const { selectedItems } = this.$refs.cpTable.getSelectedItems();
      const investorsIds = selectedItems.length ? selectedItems.map(({ id }) => id) : null;
      const labels = this.getSelectedLabels(data);
      this.$refs.labelsModal.disableOk();
      this.updateInvestorsLabels({ investorsIds, ...labels }).finally(() => {
        this.listSelectedInvest = [];
        this.selectAllCheckboxOff = !this.selectAllCheckboxOff;
        this.$refs.labelsModal.hideLabelModal();
        this.$refs.labelsModal.enableOk();
        this.$refs.cpTable.updateTableData();
      });
    },
    filterLabels(labelsList = []) {
      return labelsList.reduce((accum, { label, id }) => {
        const count = this.listSelectedInvest.filter(({ labels }) => (labels || []).some(investorLabel => investorLabel.id === id)).length;
        if (!count) {
          return { ...accum, [id]: { label } };
        }

        return {
          ...accum,
          [id]: {
            label,
            checked: count === this.listSelectedInvest.length,
            indeterminate: count < this.listSelectedInvest.length && count > 0,
          },
        };
      }, {});
    },
    editInvestorsLabels() {
      this.modalId = 'edit-label-modal';
      this.isCheckboxActive = false;
      this.hideCreateLabel = false;
      this.$refs.labelsModal.showLabelModal();
    },
    selectInvestor({ value, item }) {
      if (value) {
        this.listSelectedInvest.push(item);
        return;
      }
      const index = this.listSelectedInvest.findIndex(el => el.id === item.id);
      this.listSelectedInvest.splice(index, 1);
    },
    selectAllInvestors({ allSelect, selectedItems }) {
      this.listSelectedInvest = allSelect ? selectedItems : [];
    },
    async getAndParseLabels(q) {
      const { data: { data: { labels, predefinedLabels } } } = await this.getLabels(q);
      this.labels = labels;
      this.predefinedLabels = predefinedLabels;
    },
    getVerificationText(error) {
      const found = this.verificationErrors.find(item => item.value === error);
      return found ? found.text : error;
    },
    attorneyAssigneeChange(investorId, operatorId, assignedAttorneyOperatorId) {
      if (operatorId === assignedAttorneyOperatorId) return;
      this.$emit('assigneeAttorney', { investorId, operatorId });
    },
    parseQuery(key) {
      return this.$route.query[key] ? camelCase(this.$route.query[key]) : false;
    },
  },
};
</script>

<style scoped lang="scss">
  .headers-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .input-group{
    width: auto;
  }
</style>

<style lang="scss">
  .sec-id-investors-table-container {
    .font-family-roboto {
      font-family: "Roboto";
    }
    .custom-control{
      padding-left: 1.2rem;
    }
    .badge-grey {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
      box-shadow: 0 0 0 1px #dee0e2 inset;
      color: #dee0e2
    }
    .badge-orange {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
      box-shadow: 0 0 0 1px #F5A01D inset;
      color: #F5A01D
    }
    .badge-yellow {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
      box-shadow: 0 0 0 1px #ffd950 inset;
      color: #ffd950
    }
    .badge-green {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
      box-shadow: 0 0 0 1px #07bc77 inset;
      color: #07bc77
    }
    .badge-red {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 1px #f7321f inset;
      box-shadow: 0 0 0 1px #f7321f inset;
      color: #f7321f
    }
  }
  .custom-tooltip {
    .arrow {
      display: none;
    }
    .tooltip-inner {
      background-color: #FFF;
      color: #617d8d;
      padding: 1em 1em 0.5em 1em;
      border-radius: 4px;
      box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px #ededee;
      text-align: left;
      max-width: 250px;

      div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 12px;
          font-weight: 500;
          color: #617d8d;
          margin-bottom: 0.5em;
          width: 100%;
        }

        span {
          border-radius: 2px;
          background-color: #ff5c78;
          padding: 0.5em;
          color: #fff;
          font-size: 10px;
          font-weight: 500;
          margin: 0 0.5em 0.5em 0;
        }
      }
    }
  }
</style>
