<template>
  <div>
    <b-modal
      :id="modalId"
      ref="LabelsModal"
      body-class="p-0"
      modal-class="label-model-overwrite"
      size="lg"
      :ok-title="$t('labelModal.button.ok')"
      :cancel-title="$t('common.button.cancel')"
      ok-variant="primary"
      cancel-variant="default"
      no-close-on-esc
      :ok-disabled="(withoutChanges && modalId === 'edit-label-modal') || isDisabled || !selectedInvest"
      @ok="applyLabels"
      @hide="$emit('onHide')"
      @shown="modalRender"
    >
      <div
        slot="modal-title"
        class="w-100"
      >
        <div>
          <h5 class="my-0">
            <span class="ion ion-ios-pricetag" />
            {{ $t(`labelModal.title.${titleName}`) }}
          </h5>
        </div>
      </div>
      <div class="px-4 mt-3">
        <!-- Search -->
        <label class="nav-item navbar-text navbar-search-box p-0 active">
          <i class="ion ion-ios-search navbar-icon align-middle" />
          <span class="navbar-search-input pl-2 w-100">
            <b-input
              v-model="search"
              class="form-control navbar-text mx-2"
              :placeholder="$t('common.placeholder.search')"
              style="width: 100%; border-bottom: 1px solid rgba(10, 23, 39, 0.04) !important;"
              @input="searchHandler"
            />
          </span>
        </label>
      </div>
      <div class="d-flex flex-row mb-3 py-2 px-4">
        <div class="w-50 mr-3">
          <div>
            <p class="my-0 pt-3 font-weight-normal">
              Labels
            </p>
          </div>
          <div
            style="overflow: auto"
            class="py-3 d-flex flex-column cp-labels-list"
          >
            <b-form-checkbox
              v-for="(label, key, index) in model.labels"
              :key="index"
              v-model="label.checked"
              :indeterminate="label.indeterminate"
              :disabled="!selectedInvest"
              class="mb-1"
              @input="() => onChange(label)"
            >
              <div class="cp-custom-label">
                <span>{{ label.label }}</span>
                <b-btn
                  v-if="modalId === 'edit-label-modal'"
                  variant="default edit-operator-btn btn-xs md-btn-flat"
                  :title="$t('common.button.delete')"
                  :disabled="selectedInvest"
                  @click="openConfirmModal(label.label, key)"
                >
                  <i class="ion ion-ios-trash" />
                </b-btn>
              </div>
            </b-form-checkbox>
          </div>
        </div>
        <div class="w-50">
          <div>
            <p class="my-0 pt-3 font-weight-normal">
              {{ $t('labelModal.subTitle') }}
            </p>
          </div>
          <div
            style="overflow: auto"
            class="pt-3 d-flex flex-column cp-labels-list"
          >
            <b-form-checkbox
              v-for="(label, key) in model.predefinedLabels"
              :key="key"
              v-model="label.checked"
              :indeterminate="label.indeterminate"
              :disabled="!selectedInvest"
              class="mb-1"
            >
              <span>{{ label.label }}</span>
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <cp-input-container
        v-if="!hideCreateLabel"
        class="bg-light py-3 px-4"
      >
        <div class="label-input-wrap">
          <cp-input
            v-model="newLabel"
            name="createLabel"
            validate="excluded_value:,"
            :class="{ 'border border-danger': errorField }"
            :placeholder="$t('labelModal.placeholder.labelName')"
          />
        </div>
        <div class="create-wrap">
          <span>
            <cp-button
              variant="default"
              class="button-reset"
              :disabled="disableCreate()"
              @click="createNewLabel"
            >
              <i class="ion ion-ios-add-circle-outline" />
              {{ $t('labelModal.button.label.create') }}
            </cp-button>
          </span>
        </div>
      </cp-input-container>
    </b-modal>
    <cp-confirm-modal
      v-if="!hideCreateLabel"
      ref="cpConfirmModal"
      :title="$t('deleteLabelConfirmModal.title')"
      class="bg-light py-3 px-4"
      :item="currentLabelToDelete"
      @onOk="deleteLabel"
    >
      {{ $t('deleteLabelConfirmModal.messagePartOne') }} <strong>{{ currentLabelToDelete.labelName }}</strong>.
      <br>
      {{ $t('deleteLabelConfirmModal.messagePartTwo') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal';
import { CpInput } from '@/components/common/standalone-components/inputs';
import cpInputContainer from '@/components/common/cpInputContainer';
import CpButton from '@/components/common/standalone-components/cp-button';

export default {
  name: 'LabelsModal',
  components: {
    CpButton,
    CpConfirmModal,
    cpInputContainer,
    CpInput,
  },
  props: {
    resultFilterLabels: {
      type: Object,
      default: () => {},
      deep: true,
    },
    modalId: {
      type: String,
      required: true,
      validator: value => ['edit-label-modal', 'filter-label-modal'].indexOf(value) !== -1,
    },
    hideCreateLabel: {
      type: Boolean,
      default: false,
    },
    predefinedLabels: {
      type: Object,
      default: () => {},
      deep: true,
    },
    errorField: {
      type: Boolean,
      default: false,
    },
    selectedInvest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      newLabel: '',
      saveStartState: null,
      isDisabled: false,
      currentLabelToDelete: {},
      model: {
        labels: {},
        predefinedLabels: {},
      },
    };
  },
  computed: {
    withoutChanges() {
      return ((JSON.stringify(this.model.labels) + JSON.stringify(this.model.predefinedLabels)) === this.saveStartState);
    },
    titleName() {
      const titleName = {
        'edit-label-modal': 'edit',
        'filter-label-modal': 'show',
      };
      return titleName[this.modalId];
    },
  },
  watch: {
    resultFilterLabels(newValue) {
      this.model.labels = { ...newValue };
    },
    predefinedLabels(newValue) {
      this.model.predefinedLabels = { ...newValue };
    },
  },
  methods: {
    searchHandler: _.debounce(function () { // eslint-disable-line func-names
      this.$emit('searchLabel', this.search);
    }, 500),
    createNewLabel() {
      this.$emit('onCreateLabel', this.newLabel);
      this.newLabel = '';
    },
    modalRender() {
      this.newLabel = '';
      this.saveStartState = JSON.stringify(this.resultFilterLabels) + JSON.stringify(this.predefinedLabels);
      this.model = {
        labels: { ...this.resultFilterLabels },
        predefinedLabels: { ...this.predefinedLabels },
      };
    },
    applyLabels(env) {
      env.preventDefault();
      let emitEvent = 'onApplyEditLabels';
      if (env.componentId === 'edit-label-modal' && this.withoutChanges) return;
      if (env.componentId === 'filter-label-modal') emitEvent = 'onApplyFilterLabels';
      this.$emit(emitEvent, { ...this.resultFilterLabels, ...this.predefinedLabels });
    },
    disableCreate() {
      return !this.newLabel || this.newLabel.includes(',');
    },
    disableOk() {
      this.isDisabled = true;
    },
    enableOk() {
      this.isDisabled = false;
    },
    showLabelModal() {
      this.$refs.LabelsModal.show();
    },
    hideLabelModal() {
      this.$refs.LabelsModal.hide();
    },
    openConfirmModal(label, id) {
      this.currentLabelToDelete = { labelName: label, id };
      this.$refs.cpConfirmModal.show();
    },
    deleteLabel() {
      this.$emit('onDeleteLabel', this.currentLabelToDelete.id);
      this.currentLabelToDelete = {};
      this.$refs.cpConfirmModal.$refs.confirmModal.hide();
      this.hideLabelModal();
    },
    onChange(label) {
      label.indeterminate = false;
    },
  },
};
</script>

<style lang="scss">
  .label-model-overwrite{
    .modal-header{
      .close {
        top: 30px;
      }
    }
  }
    .cp-labels-list {
        max-height: 200px;
        overflow: auto;
    }
  .create-wrap {
    width: 28%;
    display: inline-block;
    vertical-align: top;
  }
  .label-input-wrap {
    width: 70%;
    display: inline-block;
  }
  .button-reset {
    height: 38px;
    margin-top: 0;
  }
  .cp-custom-label {
    display: flex;
    justify-content: space-between;
    width: 250px;

    span {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-word;
      height: 21px;
    }
  }
</style>
