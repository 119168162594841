<template>
  <div>
    <div v-show="isReady && isAttornyReady">
      <cp-investors-head />
      <securitize-id-investor-filter />
      <cp-securitize-id-table
        @onViewElem="toViewPage"
        @assigneeAttorney="assigneeAttorney"
      />
    </div>
    <div
      v-show="!isReady && !isAttornyReady"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        Loading Investor Data...
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import CpSecuritizeIdTable from './components/table';
import CpInvestorsHead from '~/pages/securitize-id/components/head-investors';
import SecuritizeIdInvestorFilter from './components/filter';
import routeNames from '@/utilities/routes';

export default {
  name: 'SecuritizeIdInvestors',
  metaInfo: {
    title: 'Securitize ID',
  },
  components: {
    CpInvestorsHead,
    CpSecuritizeIdTable,
    SecuritizeIdInvestorFilter,
  },
  data() {
    return {
      isReady: false,
      isAttornyReady: false,
      activeFilters: {},
    };
  },
  computed: {
    ...mapGetters('issuerList', ['currentIssuer']),
    ...mapGetters('global', ['getFilterState']),
  },
  created() {
    this.getAllIssuersList().then(() => { this.isReady = true; });
    this.fetchOperators({
      params: { 'operator-authorization': 'securitize-id' },
    }).finally(() => { this.isAttornyReady = true; });
  },
  methods: {
    ...mapActions({
      getAllIssuersList: 'issuerList/getAllSystemIssuers',
    }),
    ...mapActions('operators', ['fetchOperators']),
    ...mapActions(
      'securitizeId',
      [
        'updateSecuritizeIdAssignedAttorneyOperator',
      ],
    ),
    toViewPage({ securitizeIdProfileId }, e) {
      if (securitizeIdProfileId) {
        const routeName = routeNames.securitizeIdInvestor(securitizeIdProfileId);
        if (e.ctrlKey || e.metaKey) {
          const route = this.$router.resolve(routeName);
          window.open(route.href, '_blank');
          return;
        }
        this.$router.push(routeName);
      }
  },
  async assigneeAttorney({ investorId, operatorId }) {
    await this.updateSecuritizeIdAssignedAttorneyOperator({ investorId, operatorId });
    },
  },
};
</script>
