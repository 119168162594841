<template>
  <div>
    <div class="filters-box">
      <div class="filter">
        <sec-id-multi-select
          ref="issuer-list"
          :options="issuersOptions"
          placeholder="Issuers"
          name="issuer-list"
          :searchable="true"
          :default-selected="appliedFilters['issuer-list'] || []"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <sec-id-multi-select
          ref="countries"
          :options="countriesList"
          placeholder="Country"
          name="countries"
          :default-selected="appliedFilters.countries || []"
          :searchable="true"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div
        v-if="doDisplayStateFilter()"
        class="filter"
      >
        <sec-id-multi-select
          ref="states"
          :options="statesList"
          placeholder="State"
          name="states"
          :default-selected="appliedFilters.states || []"
          :searchable="true"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <sec-id-multi-select
          ref="investor-type"
          :options="investorTypeOptions"
          placeholder="Investor type"
          name="investor-type"
          :searchable="true"
          :default-selected="appliedFilters['investor-type'] || []"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <sec-id-multi-select
          ref="verificationStatus"
          :options="filterLists.kycStatus"
          placeholder="Verification status"
          name="verificationStatus"
          :default-selected="appliedFilters.verificationStatus || []"
          :searchable="true"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <sec-id-multi-select
          ref="verificationErrors"
          :options="filterLists.verificationErrors"
          placeholder="Verification errors"
          name="verificationErrors"
          :searchable="true"
          :default-selected="appliedFilters.verificationErrors || []"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <sec-id-multi-select
          ref="assignedAttorneyOperatorId"
          :options="attorneys"
          placeholder="Assignee"
          name="assignedAttorneyOperatorId"
          :default-selected="appliedFilters.assignedAttorneyOperatorId"
          @filtersChanged="onFiltersChange"
        />
      </div>
    </div>
    <div class="date-filters">
      <cp-date-range
        v-validate="'date_range'"
        class="input-item"
        name="datePicker"
        label="Last Login"
        :value="dateFilter"
        @input="dateFilterChange"
      />
      <cp-button
        variant="default"
        size="md"
        @click="() => clearDateFilters()"
      >
        Clear
      </cp-button>
    </div>
    <!-- Active filters labels -->
    <div class="d-flex align-items-center mt-4 flex-wrap">
      <div
        v-for="filter in selectedFiltersToShow"
        :key="filter.value"
        class="selected-filter"
      >
        <span class="mb-0">{{ `${$t(`securitizeId.filterTypes.${filter.key}`)}: ` }}{{ $t(filter.i18nKey) || filter.text }}</span>
        <i
          class="ion ion-ios-close ml-2"
          @click="() => removeFilter(filter)"
        />
      </div>
      <a
        v-if="activeFilters.length > 15"
        class="show-more-btn"
        @click="toggleFiltersShown"
      >
        <span>{{ showAllSelectedFilters ? 'Show less' : 'Show more' }}</span>
        <i
          class="d-flex align-items-center fa ml-2"
          :class="{
            'fa-chevron-down': !showAllSelectedFilters,
            'fa-chevron-up': showAllSelectedFilters,
          }"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { countriesList, filterLists } from './options';
import states from '~/utilities/us-states-list';
import SecIdMultiSelect from '@/pages/securitize-id/components/sec-id-multiselect';
import CpDateRange from '~/components/common/standalone-components/inputs/cp-date-range';
import CpButton from '~/components/common/standalone-components/cp-button';

const statesList = Object.keys(states).map(key => ({ value: key, text: states[key] }));

export default {
  name: 'SecuritizeIdInvestorFilter',
  components: {
    CpDateRange,
    CpButton,
    SecIdMultiSelect,
  },
  data() {
    return {
      filterLists,
      registrationSourceList: [],
      countriesList,
      statesList,
      appliedFilters: {},
      activeFilters: [],
      showAllSelectedFilters: false,
      dateFilter: [],
      investorTypeOptions: [
        { text: 'Individual', value: 'individual' },
        { text: 'Entity', value: 'entity' },
      ],
    };
  },
  computed: {
    ...mapGetters('global', ['issuers']),
    ...mapState('issuerList', ['allSystemIssuersList']),
    ...mapState('operators', ['operatorsList']),
    issuersOptions() {
      return [
        { text: 'None', value: 'none' },
        { text: 'All', value: 'all' },
        ...(this.allSystemIssuersList.length ? this.allSystemIssuersList : []).map(issuer => ({ text: issuer.name, value: issuer.issuerId })),
      ];
    },
    attorneys() {
      return this.operatorsList
        .filter(operator => operator.authorizations.includes('securitize-id'))
        .reduce((acc, { id, name }) => ([...acc, { value: id, text: name }]), [
          { value: 'none', text: 'None' },
          { value: 'compliance-team', text: 'Compliance Team' },
          { value: 'operations-team', text: 'Operations Team' },
        ]);
    },
    selectedFiltersToShow() {
      return this.showAllSelectedFilters ? this.activeFilters : [...this.activeFilters].slice(0, 14);
    },
  },
  watch: {
    attorneys() { this.updateFilters(); },
  },
  created() {
    this.appliedFilters = this.getFiltersFromQuery();
    const fromDate = this.$route.query['from-last-logged-in'];
    const toDate = this.$route.query['to-last-logged-in'];
    this.dateFilter = [fromDate ? decodeURIComponent(fromDate) : null,
      toDate ? decodeURIComponent(toDate) : null];
    this.activeFilters = this.getActiveFilters();
  },
  methods: {
    updateFilters() {
      this.appliedFilters = this.getFiltersFromQuery();
      this.activeFilters = this.getActiveFilters();
      const filterValues = Object.keys(this.appliedFilters)
        .reduce((accum, key) => ({ ...accum, [key]: this.appliedFilters[key].map(({ value }) => value) }), {});
      this.$emit('filtersApplied', filterValues);
    },
    onFiltersChange({ name: filterKey, filters }) {
      this.appliedFilters[filterKey] = filters;
      if (filterKey === 'countries' && this.appliedFilters.states && !filters.find(({ value }) => value === 'US')) {
        delete this.appliedFilters.states;
      }

      this.activeFilters = this.getActiveFilters();
      const values = this.getFilterValues();
      // eslint-disable-next-line no-useless-computed-key
      const { search, ['from-last-logged-in']: fromLastLoggedIn, ['to-last-logged-in']: toLastLoggedIn, page, ['order-field']: orderField, ['order-direction']: orderDirection } = this.$route.query;
      if (search) values.search = search;
      if (fromLastLoggedIn) values['from-last-logged-in'] = fromLastLoggedIn;
      if (toLastLoggedIn) values['to-last-logged-in'] = toLastLoggedIn;
      if (page) values.page = page;
      if (orderField) values['order-field'] = orderField;
      if (orderDirection) values['order-direction'] = orderDirection;

      this.$router.replace({ query: values });
    },
    removeFilter(filter) {
      this.$refs[filter.key].deleteFilter(filter.value);
      const query = { ...this.$route.query };
      if (filter.key === 'countries' && filter.value === 'US') {
        delete query.states;
      }

      query[filter.key] = this.getFilterValues(filter.key)[filter.key];
      this.$router.replace({ query });
    },
    toggleFiltersShown(e) {
      e.preventDefault();
      this.showAllSelectedFilters = !this.showAllSelectedFilters;
    },
    clearDateFilters() {
      const query = { ...this.$route.query };
      delete query['from-last-logged-in'];
      delete query['to-last-logged-in'];
      this.dateFilter = [];
      this.$router.replace({ query });
    },
    dateFilterChange(values) {
      if (values.some(val => val)) this.dateFilter = values;
      const [fromLastLoggedIn, toLastLoggedIn] = values;
      const dateFilters = {};
      if (fromLastLoggedIn) dateFilters['from-last-logged-in'] = encodeURIComponent(moment(fromLastLoggedIn).utc(true).toISOString());
      if (toLastLoggedIn) dateFilters['to-last-logged-in'] = encodeURIComponent(moment(toLastLoggedIn).utc(true).toISOString());
      this.$router.replace({ query: { ...this.$route.query, ...dateFilters } });
    },
    getFilterValues() {
      return Object.keys(this.appliedFilters)
        .reduce((accum, key) => (this.appliedFilters[key].length
          ? { ...accum, [key]: this.appliedFilters[key].map(({ value }) => value) }
          : accum), {});
    },
    getFiltersFromQuery() {
      const values = this.$route.query || {};
      const filterOptions = {
        countries: this.countriesList,
        states: this.statesList,
        'issuer-list': this.issuersOptions,
        'investor-type': this.investorTypeOptions,
        verificationStatus: this.filterLists.kycStatus,
        verificationSubStatus: this.filterLists.kycStatus,
        verificationErrors: this.filterLists.verificationErrors,
        assignedAttorneyOperatorId: this.attorneys,
      };
      return Object.keys(values)
        .reduce((accum, key) => {
          if (!filterOptions[key]) return accum;

          const selectedOptions = Array.isArray(values[key]) ? values[key] : [values[key]];
          const filter = selectedOptions.map((value) => {
            const item = filterOptions[key].find(option => option.value === value);
            return { ...item, applied: true };
          });
          return { ...accum, [key]: filter };
        }, {});
    },
    getActiveFilters() {
      return Object.keys(this.appliedFilters)
        .reduce((accum, key) => ([...accum, ...this.appliedFilters[key].map(filter => ({ ...filter, key }))]), []);
    },
    doDisplayStateFilter() {
      return !!(this.appliedFilters.countries || []).find(country => country.value === 'US');
    },
  },
};
</script>

<style lang="scss" scoped>
  .filters-box {
    display: flex;
    flex-wrap: wrap;
  }

  .filter {
    margin: .1rem;
    width: 19%;
  }

  .date-filters {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      font-size: .8em;
      height: 3.3em;
      width: 0.1em;
      margin: 0.8em 0 0 0.5em;
    }
  }

  .selected-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    border-radius: 50px;
    background-color: rgba(97, 125, 141, 0.1);
    margin: 0 1em 1em 0;
    font-size: 14px;
    font-weight: 500;
    color: #697e8d;
    text-transform: capitalize;
    i {
      font-size: 24px;
      cursor: pointer;
    }
  }

  .show-more-btn {
    color: #0283a8;
    display: flex;
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 1em 1em;
    cursor: pointer;
  }
</style>
